import { Field, useField } from 'formik';
import React, { useState, useEffect, useRef, useCallback, ReactNode } from 'react';

interface DatePickerProps {
    name: string;
    label: ReactNode;
    value: string;
    onChange?: (value: string) => void;
    error?: string;
    maxDate?: string;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    moreinfo?: string;
    disableFutureDates?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({ name, label, value, onChange, error, maxDate, onKeyDown, moreinfo, disableFutureDates }) => {
    const [field, meta, helpers] = useField(name);

    const [focusDay, setFocusDay] = useState<Date>(new Date());
    const [selectedDay, setSelectedDay] = useState<Date>(new Date(0, 0, 1));
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [focusedButton, setFocusedButton] = useState<'prev' | 'next' | null>(null); // Track the focused button

    const textboxRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dialogRef = useRef<HTMLDivElement>(null);
    const monthYearRef = useRef<HTMLDivElement>(null);
    const tbodyRef = useRef<HTMLTableSectionElement>(null);

    // Refs for the navigation buttons (previous year, previous month, etc.)
    const prevYearNode = useRef<HTMLButtonElement>(null);
    const prevMonthNode = useRef<HTMLButtonElement>(null);
    const nextMonthNode = useRef<HTMLButtonElement>(null);
    const nextYearNode = useRef<HTMLButtonElement>(null);
    const cancelButtonNode = useRef<HTMLButtonElement>(null);

    const dayLabels = ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"];
    const monthLabels = ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"];


    const handlePrevMonth = () => {
        const newDate = new Date(focusDay);
        newDate.setMonth(focusDay.getMonth() - 1);
        setFocusDay(newDate); 
        setFocusedButton('prev');
    };

    const handleNextMonth = () => {
        const newDate = new Date(focusDay);
        newDate.setMonth(focusDay.getMonth() + 1);
        setFocusDay(newDate);
        setFocusedButton('next');
    };

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0]; // Format YYYY-MM-DD
    
        if (!value) {
            onChange?.(formattedDate); // Ensure parent state gets the initial value
        }
    }, [value, onChange]);
    

    useEffect(() => {
        if (isOpen) {
            updateGrid();
            requestAnimationFrame(() => setFocusToCurrentDay());
        }
    }, [focusDay, isOpen]);

    useEffect(() => {
        const handleBlur = () => {
            close();
            setDateForButtonLabel();
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
                close();
            }
        };

        document.addEventListener('click', handleClickOutside);

        const handleChange = () => convertDateFormat();

        const textboxNode = textboxRef.current;
        textboxNode?.addEventListener("blur", handleBlur);
        textboxNode?.addEventListener("change", handleChange);

        if (focusDay) {
            updateGrid();
            setFocusToCurrentDay();
        }

        // Refocus navigation button
        if (focusedButton === 'prev') {
            setTimeout(() => prevMonthNode.current?.focus(), 0);
        } else if (focusedButton === 'next') {
            setTimeout(() => nextMonthNode.current?.focus(), 0);
        }

        return () => {
            textboxNode?.removeEventListener("blur", handleBlur);
            textboxNode?.removeEventListener("change", handleChange);
            document.removeEventListener('click', handleClickOutside);
        };
    }, [focusDay, focusedButton]);

    const moveFocusToDay = (date: Date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (!disableFutureDates || date <= today) {
            setFocusDay(date);
            requestAnimationFrame(() => {
                setFocusToCurrentDay();
            });
        }
    };

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        close(); 
    };

    const setFocusToCurrentDay = () => {
        requestAnimationFrame(() => {
            const dayCells = tbodyRef.current?.querySelectorAll('td');
            if (!dayCells) return;
        
            // Clear all aria-selected attributes first
            dayCells.forEach((cell) => {
                cell.removeAttribute("aria-selected");
                cell.tabIndex = -1; // Remove previous focusability
            });
    
            let focusCell: HTMLElement | null = null;
    
            dayCells.forEach((cell) => {
                const date = getDayFromDataDateAttribute(cell);
                if (isSameDay(date, focusDay)) {    
                    cell.setAttribute("aria-selected", "true");
                    cell.tabIndex = 0;
                    focusCell = cell;
                }
            });
    
            if (focusCell) {
                setTimeout(() => {
                    focusCell?.focus();
                }, 10); // delay to ensure DOM update
            } else {
                console.warn("No matching cell found for focusDay:", focusDay);
            }
        });
    };
    

    const updateGrid = () => {
        if (!tbodyRef.current) return;

        const today = new Date();
        today.setHours(0, 0, 0, 0); // Normalize today's date

        const fd = focusDay;
        if (monthYearRef.current) {
            monthYearRef.current.textContent = `${monthLabels[fd.getMonth()]} ${fd.getFullYear()}`;
        }

        let firstDayOfMonth = new Date(fd.getFullYear(), fd.getMonth(), 1);
        let dayOfWeek = firstDayOfMonth.getDay();
        dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() - dayOfWeek);

        const d = new Date(firstDayOfMonth);
        if (tbodyRef.current) {
            tbodyRef.current.innerHTML = ''; // Clear previous grid
        }

        let row = document.createElement('tr');
        for (let i = 0; i < 42; i++) {
            const cell = document.createElement('td');
            const dateStr = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
            cell.setAttribute('data-date', dateStr);
            const span = document.createElement('span');
            span.textContent = d.getDate().toString();
            span.setAttribute('aria-hidden', 'true');
            cell.appendChild(span);
            const isSelected = isSameDay(d, selectedDay);

            cell.tabIndex = -1; // Initially disable all days

            const shouldDisable = disableFutureDates ? d > today : false;

            if (d.getMonth() !== fd.getMonth() || shouldDisable) {
                cell.classList.add('disabled'); // Disable future and out-of-month dates
                cell.tabIndex = -1;
            } else {
                if (isSameDay(d, focusDay)) {
                    cell.tabIndex = 0; // Focusable only if it's a valid day
                }
                if (isSelected) {
                    cell.setAttribute("aria-selected", "true");
                }
            }

            cell.addEventListener('click', handleDayClick);
            cell.addEventListener('keydown', handleDayKeyDown);
            cell.setAttribute("role", "gridcell");
            cell.focus();

            row.appendChild(cell);
            if (row.children.length === 7) {
                tbodyRef.current?.appendChild(row);
                row = document.createElement('tr');
            }

            d.setDate(d.getDate() + 1);

            // Update the aria-label for each valid day cell
            const swedishDays = ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"];
            const swedishMonths = ["januari", "februari", "mars", "april", "maj", "juni", "juli", "augusti", "september", "oktober", "november", "december"];

            const allDays = tbodyRef.current?.querySelectorAll('td');
            allDays?.forEach((cell) => {
                if (!cell.classList.contains('disabled')) {
                    const dateParts = cell.getAttribute('data-date')?.split('-');
                    if (dateParts) {
                        const day = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]));
                        const dayOfWeek = swedishDays[day.getDay()];
                        const dayOfMonth = day.getDate();
                        const month = swedishMonths[day.getMonth()];

                        const ariaLabel = `${dayOfWeek} ${dayOfMonth} ${month}`;
                        cell.setAttribute('aria-label', ariaLabel);
                    }
                }
            });

            // Mark all header cells as presentation elements
            const headerCells = document.querySelectorAll("th[scope='col']");
            headerCells.forEach((headerCell) => {
                headerCell.setAttribute("aria-hidden", "true");
            });
        }

        requestAnimationFrame(() => {
            const dayCells = tbodyRef.current?.querySelectorAll('td');
            if (!dayCells) return;

            // First, clear aria-labels to prevent duplicates
            dayCells.forEach((cell) => {
                cell.removeAttribute("aria-label");
            });

            dayCells.forEach((cell) => {
                const date = getDayFromDataDateAttribute(cell);
                if (!cell.classList.contains("disabled")) {
                    const ariaLabel = `${dayLabels[date.getDay()]} ${date.getDate()} ${monthLabels[date.getMonth()]}`;
                    cell.setAttribute("aria-label", ariaLabel);
                }
            });

            // move focus after setting aria-label
            setFocusToCurrentDay();
        });
    };


    const handleDayClick = useCallback((e: MouseEvent) => {
        const cell = (e.target as HTMLElement).closest("td");
        if (!cell || cell.classList.contains("disabled")) return;
    
        const date = getDayFromDataDateAttribute(cell);

        setFocusDay(date);
        setSelectedDay(date);
    
        setTimeout(() => {
            setTextboxDate(cell as HTMLTableCellElement);
            setFocusToCurrentDay();
        }, 10);
    }, []);
    


    const handleDayKeyDown = useCallback((e: KeyboardEvent) => {
        const target = e.target as HTMLTableCellElement;
        const key = e.key;
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        let newDate = new Date(focusDay);

        if (key === 'ArrowRight') {
            newDate.setDate(focusDay.getDate() + 1);
            if (!disableFutureDates || newDate <= today) {
                moveFocusToDay(newDate);
            }
            e.preventDefault();
        } else if (key === 'ArrowLeft') {
            newDate.setDate(focusDay.getDate() - 1);
            moveFocusToDay(newDate);
            e.preventDefault();
        } else if (key === 'ArrowDown') {
            newDate.setDate(focusDay.getDate() + 7);
            if (!disableFutureDates || newDate <= today) {
                moveFocusToDay(newDate);
            }
            e.preventDefault();
        } else if (key === 'ArrowUp') {
            newDate.setDate(focusDay.getDate() - 7);
            moveFocusToDay(newDate);
            e.preventDefault();
        } else if (key === 'Enter' || key === ' ') {
            if (!disableFutureDates || focusDay <= today) {
                setTextboxDate(target);
                close();
            }
            e.preventDefault();
        } else if (key === 'Escape') {
            close();
            e.preventDefault();
        }
    }, [focusDay, disableFutureDates]);


    const moveFocusToNextDay = () => {
        const d = new Date(focusDay);
        d.setDate(focusDay.getDate() + 1);
        moveFocusToDay(d);
    };

    const moveFocusToPreviousDay = () => {
        const d = new Date(focusDay);
        d.setDate(focusDay.getDate() - 1);
        moveFocusToDay(d);
    };

    const moveFocusToNextWeek = () => {
        const d = new Date(focusDay);
        d.setDate(focusDay.getDate() + 7);
        moveFocusToDay(d);
    };

    const moveFocusToPreviousWeek = () => {
        const d = new Date(focusDay);
        d.setDate(focusDay.getDate() - 7);
        moveFocusToDay(d);
    };

    const isDayDisabled = (domNode: HTMLTableCellElement) => {
        return domNode.classList.contains('disabled');
    };

    const getDayFromDataDateAttribute = (domNode: HTMLTableCellElement) => {
        const parts = domNode.getAttribute('data-date')?.split('-');
        return new Date(parts ? parseInt(parts[0]) : 0, parts ? parseInt(parts[1]) - 1 : 0, parts ? parseInt(parts[2]) : 0);
    };

    const isSameDay = (d1: Date, d2: Date) => {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    };

    const formatDate = (date: Date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    };

    const setTextboxDate = (domNode: HTMLTableCellElement) => {
        if (!domNode) return;

        let d = getDayFromDataDateAttribute(domNode);
        const formattedDate = formatDate(d); // Use local date formatting

        if (textboxRef.current) {
            textboxRef.current.value = formattedDate;
        }

        setSelectedDay(d);
        setFocusDay(d);

        if (!onChange && helpers.setValue) {
            helpers.setValue(formattedDate);
        } else if (onChange) {
            onChange(formattedDate);
        }
    };

    const setDateForButtonLabel = () => {
        const parts = textboxRef.current?.value.split('-');
        if (parts && parts.length === 3) {
            const year = parseInt(parts[0]);
            const month = parseInt(parts[1]);
            const day = parseInt(parts[2]);

            if (Number.isInteger(year) && Number.isInteger(month) && Number.isInteger(day)) {
                const date = new Date(year, month - 1, day);
                let label = "Ändra Datum, ";
                label += `${dayLabels[date.getDay()]} ${date.getDate()} ${monthLabels[date.getMonth()]}, ${year}`;
                buttonRef.current?.setAttribute('aria-label', label);
            }
        } else {
            buttonRef.current?.setAttribute('aria-label', 'Välj Datum');
        }
    };

    const convertDateFormat = () => {
        const inputValue = textboxRef.current?.value;
        if (inputValue && inputValue.length === 10 && inputValue[4] === '/' && inputValue[7] === '/') {
            const formattedDate = `${inputValue.slice(0, 4)}-${inputValue.slice(5, 7)}-${inputValue.slice(8, 10)}`;
            textboxRef.current.value = formattedDate;
        }
    };

    const getDateFromTextbox = () => {
        const parts = textboxRef.current?.value.split("-");
        const currentYear = new Date().getFullYear();

        if (
            parts?.length === 3 &&
            !isNaN(parseInt(parts[0])) &&
            !isNaN(parseInt(parts[1])) &&
            !isNaN(parseInt(parts[2]))
        ) {
            let year = parseInt(parts[0]);
            let month = parseInt(parts[1]) - 1; // Adjust for zero-based month
            let day = parseInt(parts[2]);

            if (year < 100) {
                year += 2000; // Convert to proper four-digit year
            }

            const newDate = new Date(year, month, day);

            setFocusDay(newDate);
            setSelectedDay(newDate);

            if (!isNaN(newDate.getTime())) {
            } else {
                console.warn("Invalid date:", textboxRef.current?.value);
            }
        }
    };

    const handleOpenButton = () => {
        if (!isOpen) {
            open();
        } else {
            close();
        }
    };

    const open = () => {
        if (!field.value) {
            if (!selectedDay || selectedDay.getTime() === new Date(0, 0, 1).getTime()) {
                setFocusDay(new Date()); // Only set focusDay to today if no selection exists
            }
        } else {
            setFocusDay(new Date(field.value));
        }
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
    };

    const handleCancelButton = (event: React.KeyboardEvent | React.MouseEvent) => {
        let flag = false;

        switch (event.type) {
            case "keydown":
                const keyboardEvent = event as React.KeyboardEvent;
                switch (keyboardEvent.key) {
                    case "Tab":
                        // Prevent default Tab behavior (which moves focus)
                        event.preventDefault();

                        if (!(keyboardEvent.shiftKey)) {
                            // Focus on the prevMonthNode (next in tab order)
                            prevMonthNode.current?.focus();
                            flag = true;
                        } else {
                            setFocusToCurrentDay();
                            flag = true;
                        }
                        break;

                    case "Escape":
                    case "Esc":
                        close();
                        flag = true;
                        break;

                    default:
                        break;
                }
                break;

            case "click":
                close();
                flag = true;
                break;

            default:
                break;
        }

        if (flag) {
            event.stopPropagation();
            event.preventDefault();
        }
    };

    const handlePreviousMonthButton = (event: React.KeyboardEvent | React.MouseEvent) => {
        let flag = false;

        if (event.type === "keydown") {
            const keyboardEvent = event as React.KeyboardEvent;

            switch (keyboardEvent.key) {
                case "Escape":
                case "Esc":
                    close();
                    flag = true;
                    break;

                case "Tab":
                    if (keyboardEvent.shiftKey) {
                        cancelButtonNode.current?.focus();
                        flag = true;
                    }
                    break;

                case "Enter":
                    handlePrevMonth();
                    prevMonthNode.current?.focus();

                    flag = true;
                    break;

                default:
                    break;
            }
        }

        if (flag) {
            event.stopPropagation();
            event.preventDefault();
        }
    };

    const handleNextMonthButton = (event: React.KeyboardEvent | React.MouseEvent) => {
        let flag = false;

        if (event.type === "keydown") {
            const keyboardEvent = event as React.KeyboardEvent;

            switch (keyboardEvent.key) {
                case "Escape":
                case "Esc":
                    close();
                    flag = true;
                    break;

                case "Tab":
                    if (keyboardEvent.shiftKey) {
                        prevMonthNode.current?.focus();
                        flag = true;
                    }
                    break;

                case "Enter":
                    handleNextMonth();
                    nextMonthNode.current?.focus();

                    flag = true;
                    break;

                default:
                    break;
            }
        }

        if (flag) {
            event.stopPropagation();
            event.preventDefault();
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target;
        if (!value) return;

        getDateFromTextbox();
    };

    return (
        <div className="input-container">

            <div id="myDatepicker" className="datepicker" ref={dialogRef}>

                <label htmlFor={name} className="required">
                    <span>{label}</span>
                    {moreinfo && (
                        <span className="more-info">
                            <span aria-hidden="true">{moreinfo}</span>
                        </span>
                    )}
                    {error && (
                        <span className="validation-error">
                            {error.toString()}{" "}
                        </span>
                    )}
                    {meta.error && (
                        <span className="validation-error">
                            {meta.error.toString()}{" "}
                        </span>
                    )}
                </label>

                <div className='input-wrapper'>
                    {onChange ? (
                        <input
                            ref={textboxRef}
                            value={value || focusDay.toISOString().split('T')[0]}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                            onKeyDown={onKeyDown}
                            name={name}
                            id={name}
                            type="text"
                            className={`searchfield__inner field text date-field float-left ${error || meta.error ? "has-error" : ""}`}                            
                        />
                    ) : (
                        <Field
                            required
                            className={`${error || meta.error ? "has-error" : ""}`}
                            type="text"
                            {...field}
                            onChange={handleInputChange}
                            id={name}
                            name={name}
                            value={field.value || focusDay.toISOString().split('T')[0]}
                        />
                    )}

                    <div className="picker-button">
                        <button
                            type="button"
                            className={`icon ${error || meta.error ? "has-error" : ""}`}
                            aria-label="Välj datum"
                            onClick={handleOpenButton}
                        >
                            <i className="fa-solid fa-calendar-days"></i>
                        </button>
                    </div>
                </div>

                {isOpen && (
                    <div id={`datepickerDialog${name}`} className="datepicker-dialog" role="dialog" aria-modal="true" aria-label="Välj datum">
                        <div className="dialog-message" aria-live="off">Datum kan navigeras med piltangenterna</div>
                        <div className="header">
                            <button
                                type="button"
                                ref={prevMonthNode}
                                className="prev-month"
                                aria-label="föregående månad"
                                onClick={handlePrevMonth}
                                onKeyDown={handlePreviousMonthButton}
                            ></button>
                            <h2 id="id-grid-label" className="month-year" aria-live="polite" ref={monthYearRef}></h2>
                            <button
                                type="button"
                                ref={nextMonthNode}
                                className="next-month"
                                aria-label="nästa månad"
                                onClick={handleNextMonth}
                                onKeyDown={handleNextMonthButton}
                            ></button>
                        </div>
                        <div className="table-wrap">
                            <table className="dates" role="grid" aria-labelledby="id-grid-label">
                                <thead>
                                    <tr>
                                        <th scope="col" abbr="Måndag">Mån</th>
                                        <th scope="col" abbr="Tisdag">Tis</th>
                                        <th scope="col" abbr="Onsdag">Ons</th>
                                        <th scope="col" abbr="Torsdag">Tors</th>
                                        <th scope="col" abbr="Fredag">Fre</th>
                                        <th scope="col" abbr="Lördag">Lör</th>
                                        <th scope="col" abbr="Söndag">Sön</th>
                                    </tr>
                                </thead>
                                <tbody ref={tbodyRef}>
                                    {/* Grid of dates will be dynamically rendered here */}
                                </tbody>
                            </table>
                        </div>
                        <div className="dialog-ok-cancel-group">
                            <button
                                ref={cancelButtonNode}
                                className="dialog-button small btn secondary  travel-search-button"
                                value="cancel"
                                onClick={handleCancelButton}
                                onKeyDown={handleCancelButton}
                            >
                                Stäng
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DatePicker;
